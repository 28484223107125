import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Text,
} from "components";
import addBeneficiary from 'utils/client/add_beneficiary';

const relation_data = ["Relative", "Friend", "Employee", "Other"];

const AddBeneficiaryModal = ({ handleModalClose, handleTrigger }) => {
  const navigate = useNavigate();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("");
  const [relation, setRelation] = useState("");
  const [DOB, setDOB] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [reset, setReset] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleValidation = () => {
    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[0-9]{10,}\/?[0-9]*$/;
    // TODO: Relation, DOB, country, address
    if (isEmpty(fname)) setMessage("Please enter your first name.");
    else if (!nameRegex.test(fname))
      setMessage("First name in the wrong format (eg. John)");
    else if (isEmpty(lname)) setMessage("Please enter your last name.");
    else if (!nameRegex.test(lname))
      setMessage("Last name in the wrong format (eg. Doe)");
    else if (isEmpty(email)) setMessage("Please enter your email address.");
    else if (!emailRegex.test(email))
      setMessage("Email address in the wrong format. (eg. johndoe@gmail.com)");
    else if (isEmpty(gender)) setMessage("Please enter your gender.");
    else if (!["Male", "Female"].includes(gender))
      setMessage("Select an appropriate gender for the beneficiary.");
    else if (isEmpty * phoneNumber)
      setMessage("Please enter your phone number.");
    else if (!phoneRegex.test(phoneNumber))
      setMessage("Phone number in the wrong format. (eg. 0235321234)");
    else if (isEmpty(relation)) setMessage("Please select a relation");
    else if (!relation_data.includes(relation))
      setMessage("Select an appropriate relation with the beneficiary.");
    else if (isEmpty(DOB))
      setMessage("Please enter your beneficary's date of birth.");
    else if (isEmpty(city))
      setMessage("Please enter your beneficiary's country");
    else if (isEmpty(address))
      setMessage("Please provide the address of your beneficiary");
    else return true;

    return false;
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(file);
      setPreviewImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Function to cancel the uploaded image
  const cancelUploadedImage = () => {
    setPreviewImage(null);
    setUploadedImage(null);
  };

  const isEmpty = (data) => {
    if (data === null || data.length === 0) return true;
    else return false;
  };

  const handleReset = async () => {
    setClearing(true);
    setFname("");
    setLname("");
    setRelation("");
    setDOB("");
    setCity("");
    setAddress("");
    setEmail("");
    setGender("");
    setPhoneNumber("");
    setReset(false);
    setClearing(false);
  };

  const handleSubmit = async () => {
    setProcessing(true);
    setMessage("");

    if (handleValidation()) {
      const response = await addBeneficiary(fname, lname, relation, gender, DOB, city, address, email, phoneNumber, uploadedImage);
      if (response?.success) {
        setSaveModal(true);
        handleTrigger(Math.random() * 257);
      }
      else
        setMessage("Failed to add beneficiary. Try again later.")
    }
    setProcessing(false);
  };

  return (
    <>
      {saveModal && (
        <section className="fixed w-full h-full z-30 bg-lightBlack flex justify-center items-center">
          <div className="w-[368px] sm:w-[310px] h-[267px] p-[10px] bg-white-A700 rounded-[10px] flex flex-col items-center justify-center">
            <img
              src="/images/checkicon.svg"
              alt="checkIcon"
              className="w-[70px] h-[70px]"
            />

            <p className="text-[15px] font-semibold font-montserrat text-[#667185] mt-[30px] text-center">
              Form has been successfully <br />
              submitted
            </p>

            <button
              onClick={() => {
                setSaveModal(false);
                handleModalClose(false);
                navigate("/beneficiariesindex", true);
              }}
              className="bg-indigo-800 text-[#fff] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px]"
            >
              Ok, got it!
            </button>
          </div>
        </section>
      )}
      {/* Reset Modal Menu */}

      {reset && (
        <section className="fixed w-full h-full z-40 bg-lightBlack flex justify-center items-center">
          <div className="w-[368px] sm:w-[310px] p-[10px] bg-white-A700 rounded-[10px] h-[267px] flex flex-col items-center justify-center">
            <img
              src="/images/reset-icon.svg"
              alt="checkIcon"
              className="w-[70px] h-[70px]"
            />

            <p className="text-[15px] font-semibold font-montserrat text-[#667185] mt-[30px] text-center">
              Resetting would clear all the forms <br /> filled, Do you wish to
              continue?
            </p>

            <div className="flex items-center gap-[10px]">
              <button
                onClick={() => setReset(false)}
                className="bg-[#fff] text-[#B00020] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px] border border-[#B00020]"
              >
                Cancel
              </button>

              <button
                onClick={() => handleReset()}
                className="bg-indigo-800 text-[#fff] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px]"
                disabled={clearing}
              >
                {clearing ? "Resetting..." : "Yes, Reset"}
              </button>
            </div>
          </div>
        </section>
      )}
      <section
        className="fixed w-full z-10 h-full mb-[60px] bg-lightBlack flex justify-center"
      >
        <div className="w-[647px] md:w-[400px] sm:w-[300px] mt-[60px] mb-[60px] pb-[60px] bg-white-A700 rounded-[10px] h-full overflow-auto">
          <div className="p-[20px] border-b border-gray-100 flex justify-between">
            <p className="text-indigo-800 text-[20px] sm:text-[18px] font-bold font-montserrat">
              Add Beneficiary
            </p>
            <Button
              onClick={() => handleModalClose(false)}
            >
              <Text
                size="txtMontserratSemiBold20"
                className="text-indigo-800 font-bold text-[20px]"
              >
                X
              </Text>
            </Button>
          </div>
          {message?.length > 0 &&
            <p className="px-[32px] font-medium text-red-600 text-center">
              {message}
            </p>
          }
          <div className="px-[32px] py-[20px] border-b border-gray-100">
            <p className="text-blue_gray-900 text-[20px] sm:text-[17px] font-bold font-montserrat">
              Upload Image
            </p>
          </div>
          <div className="p-[32px]">
            {uploadedImage ? (
              <div className="flex items-center justify-center relative px-[21] py-[20] mb-[36px] rounded-[10px] w-[135px] h-[134px] bg-gray-100">
                <img src={previewImage} alt="uploaded_image" />
                <div
                  className="rounded-full cursor-pointer shadow-md absolute top-[-20px] right-[-8px] bg-white-A700 w-[40px] h-[40px] p-[6px] items-center justify-center"
                  onClick={cancelUploadedImage}
                >
                  <img
                    src="/images/close.svg"
                    alt=""
                    className="w-[30px] h-[30px]"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex items-center justify-center relative px-[21] py-[20] mb-[36px] rounded-[10px] w-[135px] h-[134px] bg-gray-100 cursor-pointer"
                onClick={() => fileInputRef.current.click()}
              >
                <img
                  src="/images/image-placeholder.svg"
                  alt="image_placeholder"
                />
                <div className="rounded-full absolute top-[-10px] right-[-8px] bg-white-A700 w-[32px] h-[32px] p-[6px] items-center justify-center"></div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                />
                <div className="rounded-full cursor-pointer shadow-md absolute top-[-10px] right-[-8px] bg-white-A700 w-[32px] h-[32px] p-[6px] items-center justify-center">
                  <img
                    src="/images/pencil-icon.svg"
                    alt="pencil"
                    width={93}
                    height={94}
                  />
                </div>
              </div>
            )}
            <p className="text-blue-gray-500 text-[15px] sm:text-[14px] font-normal font-montserrat">
              Allowed file types: png, jpg, jpeg
            </p>

            <p className="text-[15px] font-semibold font-montserrat text-[#667185] mt-[30px] text-center">
              Form has been successfully <br />
              submitted
            </p>

            <button
              onClick={() => {
                setSaveModal(false);
                handleModalClose(false);
                navigate("/beneficiariesindex", true);
              }}
              className="bg-indigo-800 text-[#fff] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px]"
            >
              Ok, got it!
            </button>
          </div>
        </div>
      </section>


      {/* Reset Modal Menu */}

      {reset && (
        <section className="fixed w-full h-full z-40 bg-lightBlack flex justify-center items-center">
          <div className="w-[368px] sm:w-[310px] p-[10px] bg-white-A700 rounded-[10px] h-[267px] flex flex-col items-center justify-center">
            <img
              src="/images/reset-icon.svg"
              alt="checkIcon"
              className="w-[70px] h-[70px]"
            />

            <p className="text-[15px] font-semibold font-montserrat text-[#667185] mt-[30px] text-center">
              Resetting would clear all the forms <br /> filled, Do you wish to
              continue?
            </p>

            <div className="flex items-center gap-[10px]">
              <button
                onClick={() => setReset(false)}
                className="bg-[#fff] text-[#B00020] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px] border border-[#B00020]"
              >
                Cancel
              </button>

              <button
                onClick={() => handleReset()}
                className="bg-indigo-800 text-[#fff] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px] mt-[30px]"
                disabled={clearing}
              >
                {clearing ? "Resetting..." : "Yes, Reset"}
              </button>
            </div>
          </div>
        </section>
      )}
      <section className="fixed w-full z-10 h-full mb-[60px] bg-lightBlack flex justify-center">
        <div className="w-[647px] md:w-[400px] sm:w-[300px] mt-[60px] mb-[60px] pb-[60px] bg-white-A700 rounded-[10px] h-full overflow-auto">
          <div className="p-[20px] border-b border-gray-100 flex justify-between">
            <p className="text-indigo-800 text-[20px] sm:text-[18px] font-bold font-montserrat">
              Add Beneficiary
            </p>
            <Button onClick={() => handleModalClose(false)}>
              <Text
                size="txtMontserratSemiBold20"
                className="text-indigo-800 font-bold text-[20px]"
              >
                X
              </Text>
            </Button>
          </div>
          <div className="px-[32px] py-[20px] border-b border-gray-100">
            <p className="text-blue_gray-900 text-[20px] sm:text-[17px] font-bold font-montserrat">
              Add beneficiary image
            </p>
          </div>
          <div className="p-[32px]">
            {uploadedImage ? (
              <div className="flex items-center justify-center relative px-[21] py-[20] mb-[36px] rounded-[10px] w-[135px] h-[134px] bg-gray-100">
                <img src={uploadedImage} alt="uploaded_image" />
                <div
                  className="rounded-full cursor-pointer shadow-md absolute top-[-20px] right-[-8px] bg-white-A700 w-[40px] h-[40px] p-[6px] items-center justify-center"
                  onClick={cancelUploadedImage}
                >
                  <img
                    src="/images/close.svg"
                    alt=""
                    className="w-[30px] h-[30px]"
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex items-center justify-center relative px-[21] py-[20] mb-[36px] rounded-[10px] w-[135px] h-[134px] bg-gray-100 cursor-pointer"
                onClick={() => fileInputRef.current.click()}
              >
                <img
                  src="/images/image-placeholder.svg"
                  alt="image_placeholder"
                />
                <div className="rounded-full absolute top-[-10px] right-[-8px] bg-white-A700 w-[32px] h-[32px] p-[6px] items-center justify-center"></div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                />
                <div className="rounded-full cursor-pointer shadow-md absolute top-[-10px] right-[-8px] bg-white-A700 w-[32px] h-[32px] p-[6px] items-center justify-center">
                  <img
                    src="/images/pencil-icon.svg"
                    alt="pencil"
                    width={93}
                    height={94}
                  />
                </div>
              </div>
            )}
            <p className="text-blue-gray-500 text-[15px] sm:text-[14px] font-normal font-montserrat">
              Allowed file types: png, jpg, jpeg
            </p>

            <div className="flex gap-[20px] flex-col mt-[20px]">
              <div className="flex justify-between gap-[20px]">
                <div className="flex-1">
                  <p className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                    First Name <span className="text-[#F56630]">*</span>
                  </p>
                  <input
                    type="text"
                    value={fname}
                    placeholder={"Kwabena"}
                    pattern="^[A-Za-z]+$"
                    className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01 text-blue_gray-900_01 border-none px-[12px] py-[16px] rounded-[12px] h-[48px] flex items-center w-full text-[15px] sm:text-[14px] font-semibold font-montserrat"
                    onChange={(e) => setFname(e.target.value)}
                  />
                </div>
                <div className="flex-1">
                  <p className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                    Last Name <span className="text-[#F56630]">*</span>
                  </p>
                  <input
                    type="text"
                    value={lname}
                    placeholder={"Mensah"}
                    pattern="^[A-Za-z]+$"
                    className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01 text-blue_gray-900_01 border-none px-[12px] py-[16px] rounded-[12px] h-[48px] flex items-center w-full text-[15px] sm:text-[14px] font-semibold font-montserrat"
                    onChange={(e) => setLname(e.target.value)}
                  />
                </div>
              </div>

              <div className="">
                <p className="text-[15px] sm:text-[14px]  text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                  Relation <span className="text-[#F56630]">*</span>
                </p>
                <select
                  value={relation}
                  className="bg-[#F0F2F5] flex items-center justify-between border-none px-[12px] py-[16px] rounded-[12px] w-full font-montserrat font-semibold"
                  onChange={(e) => setRelation(e.target.value)}
                >
                  <option value="">Who is the beneficiary to you?</option>
                  {relation_data.map((relation) => (
                    <option key={relation} value={relation}>
                      {relation}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                  Date of Birth <span className="text-[#F56630]">*</span>
                </p>
                <input
                  type="date"
                  value={DOB}
                  className="bg-[#F0F2F5] text-blue_gray-900_01 border-none px-[12px] py-[16px] rounded-[12px] h-[48px] flex items-center w-full text-[15px] sm:text-[14px] font-semibold font-montserrat"
                  onChange={(e) => setDOB(e.target.value)}
                />
              </div>
              <div>
                <p className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                  Gender <span className="text-[#F56630]">*</span>
                </p>
                <select
                  value={gender}
                  className="bg-[#F0F2F5] flex items-center justify-between border-none px-[12px] py-[16px] rounded-[12px] w-full font-montserrat font-semibold"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select a gender...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <p className="text-blue_gray-900 text-[20px]sm:text-[17px]  py-[20px] font-montserrat font-bold">
                Contact
              </p>

              <div className="flex flex-row items-center justify-between gap-[10px]">
                {/* <div className="w-full">
                <p
                  className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold"
                >
                  Country <span className="text-[#F56630]">*</span>
                </p>
                <select
                  className="bg-[#F0F2F5] justify-between border-none px-[12px] py-[8px] rounded-[12px] h-[48px] flex items-center w-full text-blue_gray-900_01 sm:text-[14px] text-[15px] font-semibold font-montserrat"
                >
                  <option value="">Select a country...</option>
                  {allCountries.map(country =>
                    <option
                      key={country.data.name}
                      value={country.data.name}
                    >
                      {country.data.name}
                    </option>
                  )}
                </select>
              </div> */}
                <div className="w-full">
                  <p
                    className="text-[15px]
         text-blue_gray-500 mb-[12px] font-montserrat sm:text-[14px]  font-semibold"
                  >
                    City <span className="text-[#F56630]">*</span>
                  </p>
                  <input
                    type="text"
                    value={city}
                    placeholder="Accra"
                    className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01
         text-blue_gray-900_01 border-none px-[12px] py-[16px] sm:text-[14px] 
          rounded-[12px] h-[48px] flex items-center w-full text-[15px] font-semibold font-montserrat"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="w-full">
                  <p
                    className="text-[15px]
         text-blue_gray-500 mb-[12px] font-montserrat sm:text-[14px]  font-semibold"
                  >
                    Address <span className="text-[#F56630]">*</span>
                  </p>
                  <input
                    type="text"
                    value={address}
                    placeholder="Accra - Ghana"
                    className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01
         text-blue_gray-900_01 border-none px-[12px] py-[16px] sm:text-[14px] 
          rounded-[12px] h-[48px] flex items-center w-full text-[15px] font-semibold font-montserrat"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <p className="text-[15px] sm:text-[14px] text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                  Email <span className="text-[#F56630]">*</span>
                </p>
                <input
                  type="email"
                  value={email}
                  placeholder="kwamens@gmail.com"
                  className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01 text-blue_gray-900_01 border-none px-[12px] py-[16px] rounded-[12px] h-[48px] flex items-center w-full text-[15px] font-semibold font-montserrat"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <p className="text-[15px] sm:text-[14px]  text-blue_gray-500 mb-[12px] font-montserrat font-semibold">
                  Phone <span className="text-[#F56630]">*</span>
                </p>
                <input
                  type="text"
                  value={phoneNumber}
                  placeholder="+233962342123"
                  pattern='^\+?[0-9]{10,}\/?[0-9]*$'
                  className="bg-[#F0F2F5] placeholder:text-blue_gray-300_01
         text-blue_gray-900_01 border-none px-[12px] py-[16px]
          rounded-[12px] h-[48px] flex items-center w-full text-[15px] font-semibold font-montserrat"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className="flex items-center justify-end mt-[10px] gap-[12px]">
                <button
                  onClick={() => setReset(true)}
                  className="bg-[#F0F2F5] text-indigo-800 font-semibold text-[15px] sm:text-[14px]  font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px]"
                >
                  Reset
                </button>
                <button
                  onClick={() => handleSubmit()}
                  className="bg-indigo-800 text-[#fff] font-semibold text-[15px] font-montserrat px-[20px] py-[12px] cursor-pointer rounded-[12px] sm:text-[14px]"
                  disabled={processing}
                >
                  {processing ? "Processing..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { AddBeneficiaryModal };
